<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/contra`" title="Contra" :columns="columns"
                routerpath="/accounting/addcontra" :formOptions="formOptions" :edit="edit" :add="add"
                :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Ledger",
          field: "ledger_id",
          url: "ledger",
          type: "dropdown",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Ledger",
          },
        },
        {
          label: "Narration",
          field: "narration",
          filterOptions: {
            enabled: true,
            placeholder: "Search Narration",
          },
        },
        {
          label: "Branch Name",
          field: "branch",
          url: "branch",
          responseValue: "name",
          type: "dropdown",
          filterOptions: {
            enabled: true,
            placeholder: "Search Branch Name",
          },
        },
        {
          label: "Cheque Name",
          field: "chequename",
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque Name",
          },
        },
        {
          label: "Cheque No",
          field: "chequeno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque No",
          },
        },
        {
          label: "Cheque Date",
          field: "cheque_date",
          type:'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Cheque date",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url: 'getUsers',
          url:'getUsers',

          responseValue:'name',
          type:'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          url:'getUsers',
          responseValue: 'name',
          url:'getUsers',

          type:'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      viewFields: [
              {
                label: 'Date',
                field: 'date',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Ledger',
                field: 'Ledgername',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Narration',
                field: 'narration',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Branch',
                field: 'branch',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Cheque Name',
                field: 'chequename',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Cheque No',
                field: 'chequeno',
                type: 'text',
                class: 'col-md-4'
              },
              {
                label: 'Cheque Date',
                field: 'cheque_date',
                type: 'text',
                class: 'col-md-4'
              },
              {
                lebel: 'Payment Info',
                field: '',
                type: 'heading',
                class: 'col-md-4'
              },
              {
                label: 'ducuments',
                field: 'contraitem',
                tableHead: ['ID', 'Ledger', 'Amount'],
                tableField: [
                  {
                    type: 'text',
                    field: 'Ledgername',
                  },
                  {
                    type: 'text',
                    field: 'amount',
                  },
                  {
                    type: 'text',
                    field: 'amount',
                  },
                ],
                type: 'multiple',
                class: "col-md-12",
              },
            ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Contra" : "Add Contra"}`,
        submitRouterPath: "/accounting/contra",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/contra`,
        getEditValue: `${baseApi}/getcontraById`,

        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: " Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            required: true,
          },
          {
            label: " Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            required: true,
          },
          {
            label: " Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            required: true,
          },
          {
            label: " Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Ledger Credit",
            name: "ledgercredit",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "ledgercredit",
            options: [],
            required: true,
          },
          {
            label: "Company Name",
            name: "companyname",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "companyname",
            options: [],
            required: true,
          },
          {
            label: "Remark",
            name: "remarks",
            value: "",
            type: "text",
            placeholder: "Enter Remarks",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Cheque NO",
            name: "chequeno",
            value: "",
            type: "number",
            placeholder: "Enter Cheque Number",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Cheque Date",
            name: "chequedate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select Cheque date",
            class: "col-md-6",
            required: true,
          },
          {
            title: "Contra Info",
            type: "formrepeater",
            inputFields: [
              {
                label: "Ledger Debit",
                name: "ledgerdebit",
                value: "",
                type: "text",
                class: "col-md-4",
                // required: {
                //   required,
                // },
              },
              {
                label: "Amount",
                name: "amount",
                value: "",
                type: "text",
                placeholder: "Enter Amount",
                class: "col-md-4",
                // required: {
                //   required,
                // },
              },
            ],
          },
        ],
      },
    };
  },

  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Contra") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
